


























































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import { GridFactory, GridModel, GridFilter, GridSearchCpt, GridPagerCpt } from '@/core/components/grid';
import { StudyFinderDialogCpt } from '@/core/components';
import { ApiKeyCollectionModel } from '@/core/webapi';
import { AutoCompleteItem } from '@/core/models';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    StudyFinderDialogCpt,
  },
})
export default class ApiKeyGridView extends AppVue {
  grid = {} as GridModel<ApiKeyCollectionModel>;
  showDialog = false;
  apiKeyId = 0;

  async created() {
    this.initializeData();
  }

  async initializeData() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.apiKey().filterApiKeys(filter),
      routeName: 'apiKeys',
      defaultFilterOverrides: new GridFilter(),
    });
  }

  async removeItem(apiKeyId: number, studyId: number) {
    if (!confirm(this.$t('confirm.studyRemoval') as string)) {
      return;
    }

    await ApiService.apiKey().removeStudy(apiKeyId, studyId);
    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.studyRemoved') as string,
      type: 'success',
    });
    await this.initializeData();
  }

  async findStudy(apiKeyId: number) {
    this.apiKeyId = apiKeyId;
    this.showDialog = true;
  }

  async onStudySelected(foundItem: AutoCompleteItem) {
    await ApiService.apiKey().addStudy(this.apiKeyId, foundItem.id);
    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.studyAdded') as string,
      type: 'success',
    });
    await this.initializeData();
  }

  closeDialog() {
    this.showDialog = false;
    this.apiKeyId = 0;
  }
}
